/* Tamaño movil*/
@media (max-width: 650px) {

    .donativoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .donativoFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;
        background-image: url('../../assets/img/Donativo.jpg');
        background-size: cover;
        background-position: center;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .donativoFoto::after,
    .donativoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .donativoExpli {
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 10px;
        font-size: 12px;
    }

    .donativoExpli h2 {
        font-size: 16px;
        font-weight: normal;
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }

    .donativoExpli a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 90px;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin: 30px;
    }

    .donativoExpli a:hover {
        height: 34px;
        width: 100px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .donativoReturn {
        height: 50px;
    }

    .donativoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 90px;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;

    }

    .donativoReturn a:hover {
        height: 34px;
        width: 100px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;

    }
}

/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .donativoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .donativoFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;
        background-image: url('../../assets/img/Donativo.jpg');
        background-size: cover;
        background-position: center;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .donativoFoto::after,
    .donativoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .donativoExpli  {
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 10px;
        font-size: 14px;
    }


    .donativoExpli h2 {
        font-size: 18px;
        font-weight: normal;
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }

    .donativoExpli a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 100px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin: 30px;
    }

    .donativoExpli a:hover {
        height: 34px;
        width: 110px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .donativoReturn {
        height: 50px;
    }

    .donativoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 110px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;

    }

    .donativoReturn a:hover {
        height: 34px;
        width: 110px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;

    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .donativoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .donativoFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;
        background-image: url('../../assets/img/Donativo.jpg');
        background-size: cover;
        background-position: center;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .donativoFoto::after,
    .donativoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .donativoExpli {
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 100px;
        padding-top: 0;
        padding-bottom: 20px;
        font-size: 16px;
    }

    .donativoExpli h2 {
        font-size: 18px;
        font-weight: normal;
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }

    .donativoExpli a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 100px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin: 30px;
    }

    .donativoExpli a:hover {
        height: 34px;
        width: 110px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .donativoReturn {
        height: 50px;
    }

    .donativoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 110px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;

    }

    .donativoReturn a:hover {
        height: 34px;
        width: 110px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;

    }
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .donativoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .donativoFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;
        background-image: url('../../assets/img/Donativo.jpg');
        background-size: cover;
        background-position: center;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .donativoFoto::after,
    .donativoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .donativoExpli {
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 2.0;
        box-sizing: border-box;
        padding: 150px;
        padding-top: 0;
        padding-bottom: 20px;
        font-size: 16px;
    }

    .donativoExpli h2 {
        font-size: 18px;
        padding-bottom: 40px;
        font-weight: normal;
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }

    .donativoExpli a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 100px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin: 30px;
    }

    .donativoExpli a:hover {
        height: 34px;
        width: 110px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .donativoReturn {
        height: 50px;
    }

    .donativoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 110px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;

    }

    .donativoReturn a:hover {
        height: 34px;
        width: 110px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;

    }
}