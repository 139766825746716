

/* Tamaño movil*/
@media (max-width: 650px) {
    .footerGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 223, 175, 0.944);
        width: 100%;
    }

    .footerLinkDani {
        font-size: 8px;
        width: 100%;
        height: 30px;
        color: black;
        text-align: center;
        padding-top: 5px;
    }

    .footerLinkDani a {
        color: black;
        text-decoration: none;
    }

    .footerLinkDani a:hover {
        color: white;
        text-decoration: none;
    }

    .footerPatro {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 10px;
    }

    .ajVic{
        width: 60px;
        height: auto;
    }
    .fGirbau{
        width: 50px;
        height: auto;
    }
    .fPuig{
        width: 60px;
        height: auto;
    }
    .fVicus {
        width: 60px;
        height: auto;
    }
    .aDausa {
        width: 30px;
        height: auto;
    }
    .logoIH {
        width: 40px;
        height: auto;
        padding: 10px;
    }
    .insta p{
        font-size: 8px;
        margin-right: 20px;
    }

    .insta {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logoLF{
        padding: 10px;
        width: 50px;
        height: auto;
    }

}

/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .footerGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 223, 175, 0.944);
    }

    .footerLinkDani {
        font-size: 10px;
        width: 100%;
        height: 30px;
        color: black;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .footerLinkDani a {
        color: black;
        text-decoration: none;
    }

    .footerLinkDani a:hover {
        color: white;
        text-decoration: none;
    }

    .footerPatro {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 10px;
    }

    .ajVic{
        width: 80px;
        height: auto;
    }
    .fGirbau{
        width: 70px;
        height: auto;
    }
    .fPuig{
        width: 80px;
        height: auto;
    }
    .fVicus {
        width: 80px;
        height: auto;
    }
    .aDausa {
        width: 50px;
        height: auto;
    }
    .logoIH {
        width: 60px;
        height: auto;
        padding: 10px;
    }
    .insta p{
        font-size: 10px;
        margin-right: 20px;
    }

    .insta {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logoLF{
        padding: 10px;
        width: 70px;
        height: auto;
    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .footerGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 223, 175, 0.944);
    }

    .footerLinkDani {
        font-size: 12px;
        width: 100%;
        height: 30px;
        color: black;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .footerLinkDani a {
        color: black;
        text-decoration: none;
    }

    .footerLinkDani a:hover {
        color: white;
        text-decoration: none;
    }

    .footerPatro {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 10px;
    }

    .ajVic{
        width: 100px;
        height: auto;
    }
    .fGirbau{
        width: 90px;
        height: auto;
    }
    .fPuig{
        width: 100px;
        height: auto;
    }
    .fVicus {
        width: 100px;
        height: auto;
    }
    .aDausa {
        width: 70px;
        height: auto;
    }
    .logoIH {
        width: 80px;
        height: auto;
        padding: 10px;
    }
    .insta p{
        font-size: 14px;
        margin-right: 20px;
    }

    .insta {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logoLF{
        padding: 10px;
        width: 90px;
        height: auto;
    }
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .footerGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 223, 175, 0.944);
    }

    .footerLinkDani {
        font-size: 12px;
        width: 100%;
        height: 30px;
        color: black;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .footerLinkDani a {
        color: black;
        text-decoration: none;
    }

    .footerLinkDani a:hover {
        color: white;
        text-decoration: none;
    }

    .footerPatro {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 10px;
    }

    .ajVic{
        width: 100px;
        height: auto;
    }
    .fGirbau{
        width: 90px;
        height: auto;
    }
    .fPuig{
        width: 100px;
        height: auto;
    }
    .fVicus {
        width: 100px;
        height: auto;
    }
    .aDausa {
        width: 70px;
        height: auto;
    }
    .logoIH {
        width: 80px;
        height: auto;
        padding: 10px;
    }
    .insta p{
        font-size: 14px;
        margin-right: 20px;
    }

    .insta {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logoLF{
        padding: 10px;
        width: 90px;
        height: auto;
    }
}