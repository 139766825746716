/* import Google Fonts style*/
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

body {
  margin: 0;
  background-color: rgba(244, 227, 187, 0.944);
}
body p{
  font-family: "Playfair Display", serif;
  font-weight: 400;
}
body h6,
body h5,
body h4,
body h3,
body h2,
body h1,
body a {
  font-family: "Roboto Slab", serif;

}

