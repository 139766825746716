/* Tamaño movil*/
@media (max-width: 650px) {

    .espiritualGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .espiritualGeneral h2 {
        font-size: 16px;
        margin-top: 40px;
        font-weight: normal;
    }

    .espiritualGeneral p {
        font-size: 12px;
    }

    .espiritualCelebracions,
    .espiritualEspais {
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 10px;
    }

    .espiritualCelebracions h2,
    .espiritualEspais h2{
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }

    .espiritualReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .espiritualReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
    .espiritualReturn {
        background-color: rgba(244, 227, 187, 0.944);
        width:100%;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .espiritualGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .espiritualGeneral h2 {
        font-size: 18px;
        margin-top: 40px;
        font-weight: normal;
    }

    .espiritualGeneral p {
        font-size: 14px;
    }
    
    .espiritualCelebracions,
    .espiritualEspais{
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 10px;
    }

    .espiritualCelebracions h2,
    .espiritualEspais h2{
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }

    .espiritualReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .espiritualReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .espiritualReturn {
        background-color: rgba(244, 227, 187, 0.944);
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .espiritualGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .espiritualGeneral h2 {
        font-size: 20px;
        margin-top: 40px;
        font-weight: normal;
    }

    .espiritualGeneral p {
        font-size: 16px;
    }
    
    .espiritualCelebracions,
    .espiritualEspais{
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 40px;
    }

    .espiritualCelebracions h2,
    .espiritualEspais h2{
        justify-content: center;
        display: flex;
        margin-bottom: 30px;
    }

    .espiritualReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .espiritualReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .espiritualReturn {
        background-color: rgba(244, 227, 187, 0.944);
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .espiritualGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .espiritualGeneral h2 {
        font-size: 20px;
        margin-top: 40px;
        font-weight: normal;
        margin-bottom: 30px;
    }

    .espiritualGeneral p {
        font-size: 16px;
    }
    
    .espiritualCelebracions,
    .espiritualEspais {
        width: 100%;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 40px;
    }

    .espiritualCelebracions h2,
    .espiritualEspais h2{
        justify-content: center;
        display: flex;
    }

    .espiritualReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .espiritualReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
    .espiritualReturn {
        background-color: rgba(244, 227, 187, 0.944);
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}