
/* Tamaño movil*/
@media (max-width: 650px) {
    .headerNav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: inset 0 0 10px rgba(176, 173, 173, 3);
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        transition: top 0.3s ease;
        /* Agregar transición para suavizar el cambio de posición */
        z-index: 1;
    }

    .headerNav ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        padding: 15px;
        background: rgba(185, 171, 140, 0.944);
        position: absolute;
        top: 40px;
        left: 0;
        box-shadow: inset 0 0 10px rgba(176, 173, 173, 3);
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    .headerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pageTitle {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        flex-grow: 1;
        text-align: right;
        margin-right: 60px;
        font-weight: normal;
    }

    .headerNav.open ul {
        display: flex;
        flex-direction: column;
    }

    .menuButton {
        background: rgba(252, 252, 252, 0.398);
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 16px;
        color: black;
        border-radius: 50px ;
        z-index: 2;
        order: -1;
        margin-left: 45px;
        margin-right: 60px;
    }

    .closeButton {
        background: rgba(60, 54, 54, 0.001);
        border: none;
        cursor: pointer;
        font-size: 14px;
        color: black;
        margin-left: 80px;
    }

    .closeButton:hover {
        color: black;

    }

    .headerGeneral {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(244, 223, 175, 0.944);
    }

    .headerNav a {
        text-decoration: none;
        color: black;
        font-size: 14px;
        
    }

    .headerNav li {
        margin-right: 0;
        margin-bottom: 15px;
    }

    
}

/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .headerNav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: inset 0 0 10px rgba(176, 173, 173, 3);
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        transition: top 0.3s ease;
        /* Agregar transición para suavizar el cambio de posición */
        z-index: 1;
    }

    .headerNav ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        padding: 20px;
        background: rgba(185, 171, 140, 0.944);
        position: absolute;
        top: 40px;
        left: 0;
        box-shadow: inset 0 0 10px rgba(176, 173, 173, 3);
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    .headerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pageTitle {
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        flex-grow: 1;
        text-align: right;
        margin-right: 60px;
        font-weight: normal;
    }

    .headerNav.open ul {
        display: flex;
        flex-direction: column;
    }

    .menuButton {
        background: rgba(252, 252, 252, 0.398);
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 16px;
        color: black;
        border-radius: 20px 20px;
        z-index: 2;
        order: -1;
        margin-left: 45px;
        margin-right: 60px;
    }

    .closeButton {
        background: rgba(60, 54, 54, 0.001);
        border: none;
        cursor: pointer;
        font-size: 16px;
        color: black;
        margin-left: 120px;
    }

    .closeButton:hover {
        color: black;

    }

    .headerGeneral {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(244, 223, 175, 0.944);
    }

    .headerNav a {
        text-decoration: none;
        color: black;
        font-size: 16px;
    }

    .headerNav li {
        margin-right: 0;
        margin-bottom: 15px;

    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .headerNav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        transition: top 0.3s ease;
        /* Agregar transición para suavizar el cambio de posición */
        z-index: 1;
    }

    .headerNav ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        margin: 35px 0;
        padding: 20px;
        margin-top:70px;
        background: rgba(185, 171, 140, 0.944);
        box-shadow: inset 0 0 10px rgba(176, 173, 173, 3);
    }

    .headerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pageTitle {
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
        flex-grow: 1;
        text-align: right;
        margin-right: 60px;
        font-weight: normal;
    }

    .headerNav.open ul {
        display: flex;
        flex-direction:column;
        width: 100%;
    }

    .menuButton {
        background: rgba(252, 252, 252, 0.398);
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
        color: black;
        border-radius: 20px 20px;
        z-index: 2;
        order: -1;
        margin-left: 45px;
        margin-right: 60px;
    }

    .closeButton {
        background: rgba(60, 54, 54, 0.001);
        border: none;
        cursor: pointer;
        font-size: 18px;
        color: black;
        margin-left: auto;
    }

    .closeButton:hover {
        color: black;

    }

    .headerGeneral {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(244, 223, 175, 0.944);
    }

    .headerNav a {
        text-decoration: none;
        color: black;
        font-size: 16px;
    }

   
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .headerNav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        transition: top 0.3s ease;
        /* Agregar transición para suavizar el cambio de posición */
        z-index: 1;
    }

    .headerNav ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        margin: 35px 0;
        padding: 20px;
        background: rgba(185, 171, 140, 0.944);
        margin-top: 70px;
        box-shadow: inset 0 0 10px rgba(176, 173, 173, 3);

    }

    .headerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .pageTitle {
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
        flex-grow: 1;
        text-align: right;
        margin-right: 60px;
        font-weight: normal;
    }

    .headerNav.open ul {
        display: flex;
        flex-direction:column;
        width: 100%;
    }

    .menuButton {
        background: rgba(252, 252, 252, 0.398);
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
        color: black;
        border-radius: 20px 20px;
        z-index: 2;
        order: -1;
        margin-left: 45px;
        margin-right: 60px;
    }

    .closeButton {
        background: rgba(60, 54, 54, 0.001);
        border: none;
        cursor: pointer;
        font-size: 18px;
        color:black;
        margin-left: auto;
    }

    .closeButton:hover {
        color: black;

    }

    .headerGeneral {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(244, 223, 175, 0.944);
    }

    .headerNav a {
        text-decoration: none;
        color: black;
        font-size: 16px;
    }

    
}