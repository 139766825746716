/* Tamaño movil*/
@media (max-width: 650px) {
    .noticiasGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli  {
        font-size: 12px;
    }

    .noticiasExpli h4{
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: normal;
    }
    .noticiasExpli h5{
        font-size: 12px;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .noticiasTitul h2{
        font-size: 16px;
        margin: 40px;
        margin-bottom: 60px;
        font-weight: normal;
    }

    .evento1,
    .evento2,
    .evento3,
    .evento4,
    .evento5,
    .evento6 {
        width: 220px;
        height: 160px;
        margin: 0 auto 30px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
    }

    .noticiasFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 50px;
        background-image: url('../../assets/img/NoticiesIn.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .noticiasFoto::after,
    .noticiasFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .noticiasReturn a {
        display: inline-block;
        background-color:rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .noticiasReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}
/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .noticiasGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli  {
        font-size: 14px;
    }

    .noticiasExpli h4{
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .noticiasExpli h5{
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .noticiasTitul h2{
        font-size: 18px;
        margin: 40px;
        margin-bottom: 60px;
        font-weight: normal;
    }

    .evento1,
    .evento2,
    .evento3,
    .evento4,
    .evento5,
    .evento6 {
        width: 260px;
        height: 180px;
        margin: 0 auto 40px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
    }

    .noticiasFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 50px;
        background-image: url('../../assets/img/NoticiesIn.jpg');
        background-size: cover;
        background-position: center;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .noticiasFoto::after,
    .noticiasFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .noticiasReturn a {
        display: inline-block;
        background-color:rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .noticiasReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .noticiasGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli  {
        font-size: 16px;
    }

    .noticiasExpli h4{
        font-size: 18px;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .noticiasExpli h5{
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .noticiasTitul h2{
        font-size: 20px;
        margin: 40px;
        margin-bottom: 60px;
        font-weight: normal;
    }

    .evento1,
    .evento2,
    .evento3,
    .evento4,
    .evento5,
    .evento6 {
        width: 280px;
        height: 200px;
        margin: 0 auto 40px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
    }

    .noticiasFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 50px;
        background-image: url('../../assets/img/NoticiesIn.jpg');
        background-size: cover;
        background-position: center;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .noticiasFoto::after,
    .noticiasFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .noticiasReturn a {
        display: inline-block;
        background-color:rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .noticiasReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .noticiasGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .noticiasExpli  {
        font-size: 16px;
    }

    .noticiasExpli h4{
        font-size: 18px;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .noticiasExpli h5{
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .noticiasTitul h2{
        font-size: 20px;
        margin: 40px;
        margin-bottom: 60px;
        font-weight: normal;
    }

    .evento1,
    .evento2,
    .evento3,
    .evento4,
    .evento5,
    .evento6 {
        width: 280px;
        height: 200px;
        margin: 0 auto 40px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
    }

    .noticiasFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 50px;
        background-image: url('../../assets/img/NoticiesIn.jpg');
        background-size: cover;
        background-position: center;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .noticiasFoto::after,
    .noticiasFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .noticiasReturn a {
        display: inline-block;
        background-color:rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .noticiasReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}

