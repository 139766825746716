/* Tamaño movil*/
@media (max-width: 650px) {
    .processoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .processoFoto {
        height: 180px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/Reglament.jpg');
        background-size: 100%;
        background-position:center 35%;
        background-repeat: no-repeat;
        position: relative;
    }
    .processoFoto::after,
    .processoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .processoFotos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .pfoto1,
    .pfoto2,
    .pfoto3,
    .pfoto4,
    .pfoto5,
    .pfoto6 {
        width: 220px;
        height: 160px;
        margin: 0 auto 30px;
        border-radius: 20px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
        font-size: 12px;
        object-fit: cover; 
    }

    .processoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .processoReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .processoExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        font-size: 12px;
    }

    .processoTitul h2 {
        font-size: 16px;
        margin: 40px;
        font-weight: normal;
    }

    .processoDia {
        font-size: 12px;
        height: 10px;
    }

}

/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .processoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .processoFoto {
        height: 180px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/Reglament.jpg');
        background-size: 100%;
        background-position:center 35%;
        background-repeat: no-repeat;
        position: relative;
    }

    .processoFoto::after,
    .processoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .processoFotos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .pfoto1,
    .pfoto2,
    .pfoto3,
    .pfoto4,
    .pfoto5,
    .pfoto6 {
        width: 260px;
        height: 180px;
        margin: 0 auto 40px;
        border-radius: 20px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
        font-size: 14px;
        object-fit: cover; 
    }

    .processoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .processoReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .processoExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        font-size: 14px;
    }

    .processoTitul h2{
        font-size: 18px;
        margin: 40px;
        font-weight: normal;
    }

    .processoDia {
        font-size: 16px;
        height: 10px;
    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .processoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .processoFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/Reglament.jpg');
        background-size: 100%;
        background-position:center 35%;
        background-repeat: no-repeat;
        position: relative;
    }

    .processoFoto::after,
    .processoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .processoFotos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .pfoto1,
    .pfoto2,
    .pfoto3,
    .pfoto4,
    .pfoto5,
    .pfoto6 {
        width: 260px;
        height: 180px;
        margin: 0 auto 40px;
        border-radius: 20px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
        font-size: 16px;
        object-fit: cover; 
    }

    .processoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .processoReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .processoExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 100px;
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 16px;
    }

    .processoTitul h2 {
        font-size: 20px;
        margin: 40px;
        font-weight: normal;
    }

    .processoDia {
        font-size: 16px;
        height: 10px;
    }
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .processoGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .processoFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/Reglament.jpg');
        background-size: 100%;
        background-position:center 35%;
        background-repeat: no-repeat;
        position: relative;
    }

    .processoFoto::after,
    .processoFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .processoFotos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }

    .pfoto1,
    .pfoto2,
    .pfoto3,
    .pfoto4,
    .pfoto5,
    .pfoto6 {
        width: 300px;
        height: 200px;
        margin: 0 auto 40px;
        border-radius: 20px;
        background-color: rgba(245, 245, 245, 0.34);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        box-shadow: inset 0 0 10px white;
        font-size: 16px;
        object-fit: cover; 
    }

    .processoReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .processoReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
    .processoTitul h2 {
        font-size: 20px;
        margin: 40px;
        font-weight: normal;
    }

    .processoDia {
        font-size: 16px;
        height: 10px;
    }

    .processoExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 2.0;
        box-sizing: border-box;
        padding: 150px;
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 16px;
    }

    
}