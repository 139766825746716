/* Tamaño movil*/
@media (max-width: 650px) {
    .historiaGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .historiaTitulo h2 {
        font-size: 16px;
        margin: 40px;
        font-weight: normal;
    }


    .historiaFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/HistoriaIn.jpg');
        background-size: 120px;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .historiaFoto::after,
    .historiaFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));

    }

    .historiaExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 12px;
    }

    .historiaReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .historiaReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}

/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .historiaGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .historiaTitulo h2 {
        font-size: 18px;
        margin: 40px;
        font-weight: normal;
    }

    .historiaFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/HistoriaIn.jpg');
        background-size: 120px;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .historiaFoto::after,
    .historiaFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));

    }

    .historiaExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 50px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
    }

    .historiaExpli h2 {
        font-size: 20px;
        margin: 40px;
    }

    .historiaReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .historiaReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .historiaGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .historiaTitulo h2 {
        font-size: 20px;
        margin: 40px;
        font-weight: normal;
    }

    .historiaFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/HistoriaIn.jpg');
        background-size: 120px;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .historiaFoto::after,
    .historiaFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));

    }

    .historiaExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 1.8;
        box-sizing: border-box;
        padding: 100px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 16px;
    }

    .historiaExpli h2 {
        font-size: 20px;
        margin: 40px;
    }

    .historiaReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .historiaReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .historiaGeneral {
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .historiaTitulo h2 {
        font-size: 20px;
        margin: 40px;
        font-weight: normal;
    }

    .historiaFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-image: url('../../assets/img/HistoriaIn.jpg');
        background-size: 120px;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .historiaFoto::after,
    .historiaFoto::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));

    }

    .historiaExpli {
        width: 100%;
        margin-bottom: 30px;
        background-color: rgba(244, 227, 187, 0.944);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        line-height: 2.0;
        box-sizing: border-box;
        padding: 150px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 16px;
    }

    .historiaExpli h2 {
        font-size: 20px;
        margin: 40px;
    }

    .historiaReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .historiaReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }
}