/* Tamaño movil*/
@media (max-width: 650px) {
    .informacionGeneral {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .informacionGeneral h2{
        font-size: 16px;
        font-weight: normal;
    }

    .informacionGeneral p {
        font-size: 12px;
    }

    .informacionUnete,
    .informacionOtras,
    .informacionDonativo,
    .informacionMensaje,
    .informacionReturn {
        width: 400px;
        margin: 0 auto 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
    }

    .informacionFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 50px;
        background-image: url('../../assets/img/Informacion.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .informacionFoto::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 10%;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .informacionDonativo a,
    .informacionOtras a,
    .informacionUnete a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
    }

    .informacionDonativo a:hover,
    .informacionOtras a:hover,
    .informacionUnete a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 60px;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .informacionReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionMensaje {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        text-align: center;
    }


    .informacionMensaje input[type="text"],
    .informacionMensaje input[type="email"],
    .informacionMensaje textarea {
        height: 10px;
        width: 330px;
        padding: 10px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 7px;
    }

    .contacto-message textarea {
        height: 50px;
        /* Hace que el textarea sea más grande */
    }

    .contacto-submit {
        text-align: center;
    }

    /* Estilos para el botón */
    .informacioMensaje input[type="submit"] {
        padding: 10px 20px;
        background-color: white;
        color: black;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .checkbox a {
        text-decoration: none;
        color: black;
        font-size: 10px;
        padding: 10px;
    }

    .checkbox {
        margin-bottom: 10px;
    }

}

/* Tamaño Tablet Vertical*/
@media (min-width: 650px) and (max-width: 768px) {
    .informacionGeneral {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .informacionGeneral h2{
        font-size: 18px;
        font-weight: normal;
    }

    .informacionGeneral p {
        font-size: 14px;
    }


    .informacionUnete,
    .informacionOtras,
    .informacionDonativo,
    .informacionMensaje,
    .informacionReturn {
        width: 400px;
        margin: 0 auto 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
    }

    .informacionFoto {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 80px;
        background-image: url('../../assets/img/Informacion.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .informacionFoto::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 10%;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .informacionDonativo a,
    .informacionOtras a,
    .informacionUnete a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
    }

    .informacionDonativo a:hover,
    .informacionOtras a:hover,
    .informacionUnete a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 14px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .informacionReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionMensaje {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        text-align: center;
    }


    .informacionMensaje input[type="text"],
    .informacionMensaje input[type="email"],
    .informacionMensaje textarea {
        height: 10px;
        width: 330px;
        padding: 10px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 7px;
    }

    .contacto-message textarea {
        height: 50px;
        /* Hace que el textarea sea más grande */
    }

    .contacto-submit {
        text-align: center;
    }

    /* Estilos para el botón */
    .informacioMensaje input[type="submit"] {
        padding: 10px 20px;
        background-color: white;
        color: black;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .checkbox a {
        text-decoration: none;
        color: black;
        font-size: 10px;
        padding: 10px;
    }

    .checkbox {
        margin-bottom: 10px;
    }
}

/* Tamaño Tablet Horizontal*/
@media (min-width: 768px) and (max-width: 1024px) {
    .informacionGeneral {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .informacionGeneral h2{
        font-size: 20px;
        font-weight: normal;
    }

    .informacionGeneral p {
        font-size: 16px;
    }


    .informacionUnete,
    .informacionOtras,
    .informacionDonativo,
    .informacionMensaje,
    .informacionReturn {
        width: 400px;
        margin: 0 auto 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
    }

    .informacionFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 80px;
        background-image: url('../../assets/img/Informacion.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .informacionFoto::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 10%;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .informacionDonativo a,
    .informacionOtras a,
    .informacionUnete a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
    }

    .informacionDonativo a:hover,
    .informacionOtras a:hover,
    .informacionUnete a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .informacionReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionMensaje {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        text-align: center;
    }


    .informacionMensaje input[type="text"],
    .informacionMensaje input[type="email"],
    .informacionMensaje textarea {
        height: 10px;
        width: 330px;
        padding: 10px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 7px;
    }

    .contacto-message textarea {
        height: 50px;
        /* Hace que el textarea sea más grande */
    }

    .contacto-submit {
        text-align: center;
    }

    /* Estilos para el botón */
    .informacioMensaje input[type="submit"] {
        padding: 10px 20px;
        background-color: white;
        color: black;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .checkbox a {
        text-decoration: none;
        color: black;
        font-size: 10px;
        padding: 10px;
    }

    .checkbox {
        margin-bottom: 10px;
    }
}

/* Tamaño Pantalla*/
@media(min-width: 1024px) {
    .informacionGeneral {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: rgba(244, 227, 187, 0.944);
    }

    .informacionGeneral h2{
        font-size: 20px;
        font-weight: normal;
    }

    .informacionGeneral p {
        font-size: 16px;
    }


    .informacionUnete,
    .informacionOtras,
    .informacionDonativo,
    .informacionMensaje,
    .informacionReturn {
        width: 400px;
        margin: 0 auto 30px;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 20px;
        box-sizing: border-box;
    }

    .informacionFoto {
        height: 250px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 80px;
        background-image: url('../../assets/img/Informacion.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
    }

    .informacionFoto::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 10%;
        bottom: 0;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(244, 227, 187, 0.944));
    }

    .informacionDonativo a,
    .informacionOtras a,
    .informacionUnete a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
    }

    .informacionDonativo a:hover,
    .informacionOtras a:hover,
    .informacionUnete a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionReturn a {
        display: inline-block;
        background-color: rgba(252, 252, 252, 0.398);
        color: black;
        text-decoration: none;
        transition: background-color 0.3s, color 0.3s, transform 0.3s;
        width: 70px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        border-radius: 20px 20px;
        margin-bottom: 40px;
    }

    .informacionReturn a:hover {
        height: 34px;
        width: 70px;
        background-color: white;
        color: black;
        transform: scale(1.1);
        border-radius: 20px 20px;
        box-shadow: 0 8px 10px black;
    }

    .informacionMensaje {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        text-align: center;
    }


    .informacionMensaje input[type="text"],
    .informacionMensaje input[type="email"],
    .informacionMensaje textarea {
        height: 10px;
        width: 330px;
        padding: 10px;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 7px;
    }

    .contacto-message textarea {
        height: 50px;
        /* Hace que el textarea sea más grande */
    }

    .contacto-submit {
        text-align: center;
    }

    /* Estilos para el botón */
    .informacioMensaje input[type="submit"] {
        padding: 10px 20px;
        background-color: white;
        color: black;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .checkbox a {
        text-decoration: none;
        color: black;
        font-size: 10px;
        padding: 10px;
    }

    .checkbox {
        margin-bottom: 10px;
    }
}